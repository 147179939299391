import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import img1 from "../assets/images/avatar/avt-3.jpg";
import img2 from "../assets/images/avatar/avt-11.jpg";
import img3 from "../assets/images/avatar/avt-1.jpg";
import img4 from "../assets/images/avatar/avt-5.jpg";
import img5 from "../assets/images/avatar/avt-7.jpg";
import img6 from "../assets/images/icon/Telegram_logo.svg.webp";
import imgdetail1 from "../assets/images/box-item/dorklord-bg.jpg";

const ItemDetails02 = () => {
  const [dataHistory] = useState([
    {
      img: img1,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img2,
      name: "Mason Woodward",
      time: "at 06/10/2021, 3:20 AM",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img3,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img4,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img5,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img6,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
  ]);
  return (
    <div className="item-details">
      <div className="tf-section tf-item-details style-2">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-left">
                <div className="media">
                  <img src={imgdetail1} alt="Axies" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <div className="meta-item">
                    <div className="left">
                      <h2>GET $DORKLORD</h2>
                    </div>
                  </div>
                  <p style={{ fontSize: "16px", marginBottom: "10px" }}>
                    Hey, ready to dive into the Solana scene? Let me give you
                    the lowdown, 'cause it feels like I've got the inside scoop.
                  </p>

                  <p style={{ fontSize: "16px", marginBottom: "10px" }}>
                    First things first, you need to grab yourself a SOLANA
                    WALLET. I mean, how cool is it to stash your digital cash in
                    something called Phantom or Solflare, right? Just add that
                    extension to your browser or snag the app, and follow the
                    steps. And hey, keep that recovery phrase safe - can't let
                    anyone mess with your SOL!
                  </p>

                  <p style={{ fontSize: "16px", marginBottom: "10px" }}>
                    Now, let's talk about STACKIN' SOME SOL. 'Cause what's a
                    wallet without a bit of Solana in it? Hit up one of those
                    easy-to-use exchanges like Binance or Coinbase, grab
                    yourself some SOL, then send it over to your brand-new
                    Solana wallet address. It's a breeze, buddy.
                  </p>

                  <p style={{ fontSize: "16px", marginBottom: "10px" }}>
                    And finally, it's time to get SWAPPIN' ON Raydium. Why just
                    hold onto your SOL when you can make moves like a pro? Head
                    on over to Raydium, connect your wallet, set your swap
                    amount, and hit that green light. Just follow the prompts on
                    your wallet screen to seal the deal, and voila! You're
                    officially a Solana pro.
                  </p>

                  <p style={{ fontSize: "16px" }}>
                    But hey, listen up - the Dork Lord crew's got your back on
                    this journey. We're not just sitting around while you figure
                    out this crypto world. Trust in Dork Lord, and we'll ride
                    this Solana wave together to financial freedom. Let's make
                    it happen!
                  </p>

                  <div
                    className="meta-item-details"
                    style={{ flexDirection: "column" }}
                  >
                    <div
                      style={{ width: "100%" }}
                      className="item-style-2 item-details"
                    >
                      <ul className="list-details">
                        <li>
                          <span>Supply : </span>
                          <h6>99,986,371.45</h6>{" "}
                        </li>
                        <li>
                          <span>Circulating supply : </span>
                          <h6>100%</h6>{" "}
                        </li>

                        <li>
                          <span>Created at : </span>
                          <h6>14 April , 2024</h6>{" "}
                        </li>
                      </ul>
                    </div>
                    <div
                      style={{ width: "100%", marginTop: "10px" }}
                      className="item-style-2"
                    >
                      <div
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                        className="item"
                      >
                        <span>
                          CA: Epf1j1XjrWzZJMELRxJc8AUmG6GFn4iNVK3KfwUzsbYC
                        </span>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="https://jup.ag/swap/SOL-Epf1j1XjrWzZJMELRxJc8AUmG6GFn4iNVK3KfwUzsbYC"
                    className="sc-button loadmore style bag fl-button pri-3"
                  >
                    <span>Buy $DORKLORD</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails02;
