import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import img1 from "../assets/images/avatar/avt-3.jpg";
import img2 from "../assets/images/avatar/avt-11.jpg";
import img3 from "../assets/images/avatar/avt-1.jpg";
import img4 from "../assets/images/avatar/avt-5.jpg";
import img5 from "../assets/images/avatar/avt-7.jpg";
import img6 from "../assets/images/icon/Telegram_logo.svg.webp";
import imgdetail1 from "../assets/images/box-item/photo_2024-04-13_20-21-30.jpg";

const ItemDetails03 = () => {
  const [dataHistory] = useState([
    {
      img: img1,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img2,
      name: "Mason Woodward",
      time: "at 06/10/2021, 3:20 AM",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img3,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img4,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img5,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img6,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
  ]);
  return (
    <div style={{ paddingBottom: "20px" }} className="item-details">
      <div className="tf-section tf-item-details style-2">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <div className="meta-item">
                    <div className="left">
                      <h2 className="heading">ABOUT DORKLORD</h2>
                    </div>
                  </div>
                  <p style={{ fontSize: "18px", marginBottom: "10px" }}>
                    While other memecoins have had their moments, DORK LORD is
                    ready to take the spotlight and become the star of the show.
                    It's a celebration of offbeat humor and creativity, blending
                    the enigmatic vibes of the underground with the irresistible
                    charm of the DORK LORD meme.
                  </p>

                  <p style={{ fontSize: "18px", marginBottom: "10px" }}>
                    Get set to hop on board the DORK LORD bandwagon and
                    experience the perfect blend of edgy references and DORK
                    LORD's signature appeal. With DORK LORD, laughter knows no
                    limits as it spreads happiness across social media platforms
                    and meme communities, winning over the hearts of internet
                    users everywhere.
                  </p>

                  <p style={{ fontSize: "18px", marginBottom: "10px" }}>
                    DORK LORD coin isn't affiliated with Matt Furie or his
                    creation Pepe the Frog. This token simply pays homage to a
                    meme we all adore and recognize.
                  </p>

                  <p style={{ fontSize: "18px", marginBottom: "10px" }}>
                    DORK LORD is a meme coin without any intrinsic value or
                    expectation of financial gain. There's no official team or
                    roadmap. The coin serves no practical purpose and is purely
                    for entertainment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-left">
                <div className="media">
                  <img src={imgdetail1} alt="Axies" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails03;
