import React, { useState } from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/images/icon/Telegram_logo.svg.webp";
import img2 from "../assets/images/icon/x-social-media-round-icon.webp";
import img3 from "../assets/images/icon/dexscreener-1687180969.png";

const WalletConnect = () => {
  const [data] = useState([
    {
      img: img1,
      title: "Telegram",
      link: "https://t.me/DorkLordOnSolana",
      description: "Come chat with the dorks on TG",
    },
    {
      img: img2,
      title: "Twitter",
      link: "https://twitter.com/SolanaDorkLord",
      description: "Help dorklord rise up by following us on Twitter",
    },
    {
      img: img3,
      title: "Dexscreener",
      link: "https://dexscreener.com/solana/8xetr6wrn5y8wzsvxmcdb3bgagtbwoeku2zzi7zzc74r",
      description:
        " Follow us on Dexscreener to get updates on our latest projects",
    },
  ]);
  return (
    <div>
      <div className="tf-connect-wallet tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-2 mg-bt-12">
                Find us here
              </h2>
              <h5 className="sub-title ct style-1 pad-400">
                Here below are all our socials media platforms where you can
                find us and get in touch with us.
              </h5>
            </div>
            <div className="col-md-12">
              <div
                style={{ justifyContent: "center" }}
                className="sc-box-icon-inner style-2"
              >
                {data.map((item, index) => (
                  <div key={index} className="sc-box-icon">
                    <div
                      className="img"
                      style={{
                        width: "50px",
                        margin: "0 auto",
                        marginBottom: "10px",
                      }}
                    >
                      <img src={item.img} alt="Axies" />
                    </div>
                    <h4 className="heading">
                      <Link to={item.link}>{item.title}</Link>{" "}
                    </h4>
                    <p className="content">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletConnect;
