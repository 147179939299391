import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import menus from "../../pages/menu";
import DarkMode from "./DarkMode";
import dorklord from "../../assets/images/logo/icon.png";
import twitter from "../../assets/images/icon/x-social-media-round-icon.webp";
import telegram from "../../assets/images/icon/Telegram_logo.svg.webp";
import dex from "../../assets/images/icon/dexscreener-removebg-preview(1).png";

const Header = () => {
  const { pathname } = useLocation();

  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    const scrollTop = window.scrollY;
    scrollTop >= 300
      ? header.classList.add("is-fixed")
      : header.classList.remove("is-fixed");
    scrollTop >= 400
      ? header.classList.add("is-small")
      : header.classList.remove("is-small");
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);
  const btnSearch = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
  };

  const searchBtn = () => {
    btnSearch.current.classList.toggle("active");
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <header id="header_main" className="header_1 js-header" ref={headerRef}>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div className="wrap-box flex">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link to="/" rel="home" className="">
                      <img src={dorklord} alt="logo" width={50} />
                    </Link>
                  </div>
                </div>
                <div
                  className="mobile-button"
                  ref={btnToggle}
                  onClick={menuToggle}
                >
                  <span></span>
                </div>
                <nav id="main-nav" className="main-nav" ref={menuLeft}>
                  <ul id="menu-primary-menu" className="menu">
                    {menus.map((data, index) => (
                      <li
                        key={index}
                        onClick={() => handleOnClick(index)}
                        className={`menu-item ${
                          data.namesub ? "menu-item-has-children" : ""
                        } ${activeIndex === index ? "active" : ""} `}
                      >
                        <Link to={data.links}>{data.name}</Link>
                        {data.namesub && (
                          <ul className="sub-menu">
                            {data.namesub.map((submenu) => (
                              <li
                                key={submenu.id}
                                className={
                                  pathname === submenu.links
                                    ? "menu-item current-item"
                                    : "menu-item"
                                }
                              >
                                <Link to={submenu.links}>{submenu.sub}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
                <div className="flat-search-btn flex">
                  <div className="header-search flat-show-search" id="s1">
                    <Link
                      to="https://twitter.com/SolanaDorkLord"
                      style={{ marginRight: "20px" }}
                      className=""
                    >
                      <img src={twitter} width={40} alt="search" />
                    </Link>

                    <Link
                      style={{ marginRight: "20px" }}
                      to="https://t.me/DorkLordOnSolana"
                      className=""
                    >
                      <img src={telegram} width={40} alt="search" />
                    </Link>
                    <Link
                      to="https://dexscreener.com/solana/d5twxj4zlvwnqudvxcjqdbsoim7woul2ccwrsuednqqt"
                      className=""
                    >
                      <img src={dex} width={40} alt="search" />
                    </Link>
                  </div>
                  <div className="sc-btn-top mg-r-12" id="site-header">
                    <Link
                      to="https://jup.ag/swap/SOL-Epf1j1XjrWzZJMELRxJc8AUmG6GFn4iNVK3KfwUzsbYC"
                      className="sc-button header-slider style style-1 wallet fl-button pri-1"
                    >
                      <span>Buy now</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DarkMode />
    </header>
  );
};

export default Header;
