import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Navigation, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import shape1 from "../../assets/images/backgroup-secsion/bg-gradient1.png";
import shape2 from "../../assets/images/backgroup-secsion/bg-gradient2.png";
import shape3 from "../../assets/images/backgroup-secsion/bg-gradient3.png";
import imgbg from "../../assets/images/backgroup-secsion/pexels-micotino-10044731.jpg";

const Slider = (props) => {
  const data = props.data;
  return (
    <div className="mainslider">
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
      >
        <SwiperSlide className={data[2].class}>
          <SliderItem item={data[2]} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

Slider.propTypes = {
  data: PropTypes.array.isRequired,
  control: PropTypes.bool,
  auto: PropTypes.bool,
  timeOut: PropTypes.number,
};
const SliderItem = (props) => (
  <div className="flat-title-page" style={{ backgroundColor: "#181520" }}>
    <div className="overlay"></div>
    <div className="swiper-container mainslider home">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="slider-item">
            <div className="themesflat-container ">
              <div className="wrap-heading flat-slider flex">
                <div className="content">
                  <h2 className="heading">{props.item.title_1}</h2>
                  <h1 className="heading mb-style">
                    <span className="tf-text s1">{props.item.title_2}</span>
                  </h1>
                  <h1 className="heading">{props.item.title_3}</h1>
                  <p className="sub-heading">{props.item.description}</p>
                  <div className="flat-bt-slider flex style2">
                    <Link
                      to="https://dexscreener.com/solana/8xetr6wrn5y8wzsvxmcdb3bgagtbwoeku2zzi7zzc74r"
                      className="sc-button header-slider style style-1 rocket fl-button pri-1"
                    >
                      <span>See chart</span>
                    </Link>
                    <Link
                      to="https://jup.ag/swap/SOL-Epf1j1XjrWzZJMELRxJc8AUmG6GFn4iNVK3KfwUzsbYC"
                      className="sc-button header-slider style style-1 note fl-button pri-1"
                    >
                      <span>Buy me</span>
                    </Link>
                  </div>
                </div>
                <div className="image">
                  <img className="img-bg" src={props.item.imgbg} alt="axies" />
                  <img src={props.item.img} alt="axies" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Slider;
