import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import heroSliderData from "../assets/fake-data/data-slider-3";
import Slider from "../components/slider/Slider";
import ItemDetails02 from "./ItemDetails02";
import ItemDetails03 from "./ItemDetails03";
import WalletConnect from "./WalletConnect";

const Home03 = () => {
  return (
    <div className="home-3">
      <Header />
      <Slider data={heroSliderData} />
      <ItemDetails02 />
      <WalletConnect />
      <ItemDetails03 />
    </div>
  );
};

export default Home03;
